import React from "react"
import { Formik, Form, FormikHelpers } from "formik"
import * as Yup from "yup"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import axios from "axios"
import Img from "gatsby-image"
import { Row, Col } from "react-grid-system"

import { TransitionProps } from "../components/ITransition"
import FirstLevelPage from "../components/FirstLevelPage"
import Field from "../components/forms/Field"
import {
  PrismicQuery,
  PrismicEdges,
  PrismicFluidImage,
  PrismicImage,
} from "../types/prismic"
import "./contact.scss"
import SEO from "../components/Seo"

export const query = graphql`
  query ContactQuery {
    prismic {
      allContact_pages {
        edges {
          node {
            title
            getform_id
            content
            map_link
            map_image
            map_imageSharp {
              childImageSharp {
                fluid(maxWidth: 473) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            address
            contacts {
              email_address
              phone_number
              sub_title
              title
            }
            meta_description
          }
        }
      }
    }
  }
`

interface FormValues {
  name: string
  email: string
  phone: string
  message: string
}

// Handles the form submission
const onSubmit = (id: string) => async (
  values: FormValues,
  { setSubmitting, resetForm, setStatus }: FormikHelpers<FormValues>
) => {
  try {
    setStatus("")
    await axios.post(`https://getform.io/f/${id}`, values)
    resetForm()
    setStatus("success")
  } catch (e) {
    setStatus("fail")
  } finally {
    setSubmitting(false)
  }
}

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  message: Yup.string().required("Required"),
})

type PageEdge = {
  title: string
  getform_id: string
  content: string
  map_link: string
  map_image: PrismicImage
  map_imageSharp: PrismicFluidImage
  address: string
  meta_description: string
  contacts: {
    title: string
    sub_title?: string
    phone_number?: string
    email_address?: string
  }[]
}

type AllPages = {
  allContact_pages: PrismicEdges<PageEdge>
}

const Contact: React.FC<PrismicQuery<AllPages> & TransitionProps> = ({
  transitionStatus,
  data: prismicData,
}) => {
  const data = prismicData.prismic.allContact_pages.edges?.[0]?.node
  if (!data) return null

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    message: "",
  }

  const hasMapImage = data.map_image?.url || data.map_imageSharp
  let mapImage = null
  if (hasMapImage)
    mapImage = data.map_imageSharp ? (
      <Img fluid={data.map_imageSharp.childImageSharp.fluid} />
    ) : (
      <img src={data.map_image.url} alt={data.map_image.alt || ""} />
    )

  return (
    <FirstLevelPage
      transitionStatus={transitionStatus}
      className="contact-page"
    >
      <SEO
        title={RichText.asText(data.title)}
        description={data.meta_description}
      />
      <div>
        <h1>{RichText.asText(data.title)}</h1>
        <Row gutterWidth={80}>
          <Col md={6} lg={12} xl={6} className="margin--bottom">
            <p className="heading--5">{RichText.asText(data.content)}</p>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit(data.getform_id)}
              validationSchema={FormSchema}
            >
              {({ isSubmitting, status }) => (
                <Form>
                  <Field name="name" label="Name" />
                  <Field type="email" name="email" label="Email" />
                  <Field name="phone" label="Phone" />
                  <Field
                    name="message"
                    label="Type something..."
                    as="textarea"
                  />
                  <div className="margin--bottom">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="button"
                    >
                      {isSubmitting ? "Sending..." : "Submit"}
                    </button>
                  </div>
                  {status === "fail" && (
                    <p className="text-color--warning">
                      There was an error sending your details.
                    </p>
                  )}
                  {status === "success" && (
                    <p>Thanks! We&rsquo;ll be in touch shortly</p>
                  )}
                </Form>
              )}
            </Formik>
            <div>
              <a href={data.map_link} target="_blank" rel="noopener noreferrer">
                {mapImage}
              </a>
            </div>
          </Col>
          <Col md={6} lg={12} xl={6}>
            <div className="body--large">
              <div className="contact__address">
                <div className="text--bold text-color--primary">
                  Boss Construction
                </div>
                {RichText.render(data.address)}
              </div>
              <div className="contacts">
                {data.contacts.map((contact, idx) => (
                  <div
                    className="contact-item margin--bottom"
                    key={`contact-item-${idx}`}
                  >
                    <div>{contact.title}</div>
                    {contact.sub_title && <div>{contact.sub_title}</div>}
                    {contact.phone_number && (
                      <div>
                        <a href={`tel:${contact.phone_number}`}>
                          {contact.phone_number}
                        </a>
                      </div>
                    )}
                    {contact.email_address && (
                      <div>
                        <a
                          href={`mailto:${contact.email_address}`}
                          className="text-color--primary"
                        >
                          {contact.email_address}
                        </a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </FirstLevelPage>
  )
}

export default Contact
